// =============================================
// CALENDAR SETTINGS
$rooms: ("room-1", "room-2", "room-3", "room-4", "room-5", "room-6", "room-7", "room-8", "room-9", "room-10");
// $rooms: ("sunroom", "monroom", "tuesroom", "wednesroom", "thursroom", "friroom", "saturroom");

$times: ("08-00","08-15","08-30","08-45","09-00","09-15","09-30","09-45","10-00","10-15","10-30","10-45","11-00","11-15","11-30","11-45","12-00","12-15","12-30","12-45","13-00","13-15","13-30","13-45","14-00","14-15","14-30","14-45","15-00","15-15","15-30","15-45","16-00","16-15","16-30","16-45","17-00","17-15","17-30","17-45","18-00","18-15","18-30","18-45","19-00","19-15","19-30","19-45","20-00"
);
// $times: ("00-00","00-15","00-30","00-45","01-00","01-15","01-30","01-45","02-00","02-15","02-30","02-45","03-00","03-15","03-30","03-45","04-00","04-15","04-30","04-45","05-00","05-15","05-30","05-45","06-00","06-15","06-30","06-45","07-00","07-15","07-30","07-45","08-00","08-15","08-30","08-45","09-00","09-15","09-30","09-45","10-00","10-15","10-30","10-45","11-00","11-15","11-30","11-45","12-00","12-15","12-30","12-45","13-00","13-15","13-30","13-45","14-00","14-15","14-30","14-45","15-00","15-15","15-30","15-45","16-00","16-15","16-30","16-45","17-00","17-15","17-30","17-45","18-00","18-15","18-30","18-45","19-00","19-15","19-30","19-45","20-00","20-15","20-30","20-45","21-00","21-15","21-30","21-45","22-00","22-15","22-30","22-45","23-00","23-15","23-30","23-45","00-00"
// );
// $times: ("midnight", "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am", "noon", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm");

$generate: "both"; // options: "class", "data", "both"


// Cell sizes
$columnWidth: 1fr; // Eg: 1fr, 400px…
$rowHeight: 1fr; // Eg: 1fr, 100px…


// =============================================
// Calendar class setup

// $template-columns: "[time] auto ";
// @each $room in $rooms {
//   $template-columns: $template-columns + "[#{$room}] #{$columnWidth} ";
// }

// Calendar rows
// $template-rows: "[room] auto ";
// @each $time in $times {
//   $template-rows: $template-rows + "[line-#{$time}] #{$rowHeight} ";
// }

.calendar {
  position: relative;
  display: grid;
  // grid-template-columns: unquote($template-columns);
  // grid-template-rows: unquote($template-rows);
  overflow: auto;
  height: '100%';
  padding: '0px';
  margin: '0px';
}

// =============================================
// Weekroom classes (.monroom)
// @each $room in $rooms {
//   @if ($generate == "class" or $generate == "both") {
//     .#{$room} { --room: #{$room};  }
//   }
//   @if ($generate == "data" or $generate == "both") {
//     .event[data-room="#{$room}"] { --room: #{$room}; }
//   }
// }


// =============================================
// Start time classes (.from-10am)
// Endtime classes (.to-10am)
// @each $time in $times {
//   @if ($generate == "class" or $generate == "both") {
//     .from-#{$time} { --start-time: line-#{$time}; }
//     .to-#{$time} { --end-time: line-#{$time} }
//   }
//   @if ($generate == "data" or $generate == "both") {
//     .event[data-start="#{$time}"] { --start-time: line-#{$time}; }
//     .event[data-end="#{$time}"] { --end-time: line-#{$time}; }
//   }
// }

// =============================================
// Position event in grid

.event,
.event-group {
  grid-column: var(--room) 1;
  grid-row: var(--start-time) / var(--end-time);
  z-index: 1;
}

.event-group {
  display: flex;
  .event { flex: 1; }
}

// =============================================
// Tracks

.room {
  grid-column: var(--room) 1;
  grid-row: 1 / 2;
  position: sticky;
  top: 0;
  z-index: 2;
}


.time {
  grid-column: time 1;
  grid-row: var(--start-time) 1;
  position: sticky;
  left: 0;
  z-index: 2;
}

// =============================================
// Highlight a room and time

.highlight-room {
  grid-column: var(--room) 1;
  grid-row: 1 / -1;
  position: relative;
}

.highlight-time,
.current-time {
  grid-column: 1 / -1;
  grid-row: var(--start-time) 1;
  position: relative;
  left: 0;
  right: 0;
}

.current-time {
  z-index: 2;
  height: 1px;
  background: red;
}


// =============================================
// Basic Theme

.event {
  margin: 2px;
  padding: 1em;
  background: white;
  box-shadow: 0 0 8px rgba(0,0,0,0.1), 0 2px 2px rgba(0,0,0,0.1);
  border-radius: 4px;
  min-width: 100px;
  min-height: 0px;
  word-wrap: break-word;
  overflow: auto;
}

.event div {
  font-size: 1.4vh;
}

.event h2 {
  font-size: 2vh;
}

.room {
  text-align: center;
  background: white;
  padding: 0.1em;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}

.time {
  background: white;
  padding: 4px 1em;
  font-size: 0.75em;
  color: #AAA;
  border-right: 1px solid rgba(0,0,0,0.1);
  border-top: 1px solid rgba(0,0,0,0.1);
}

.highlight-room {
  background: rgba(0, 0, 0, 0.03);
}

.highlight-time {
  background: rgba(0, 0, 0, 0.03);
}

.event:hover
{
  background: #BBD5D2;
  cursor: pointer;
}

.event.allrooms
{
  grid-column: 2 / -1;
  text-align: center;
}

body { font-family: sans-serif;
  font-size: 0.8em; }
@media screen and (min-width : 700px) {
body { font-family: sans-serif;
  font-size: 0.8em; }
}

@media screen and (max-width: 700px) {
  body { font-family: sans-serif;
    font-size: 1em; }
    // .event div {
    //   font-size: 1.1vh;
    // }
    
    // .event h2 {
    //   font-size: 1.6vh;
    // }
}

// @media screen and (max-width: 1400px){
//   .event div {
//     font-size: 1.2vh;
//   }
  
//   .event h2 {
//     font-size: 1.8vh;
//     margin-bottom: 0.5em;
//   }
// }